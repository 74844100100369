// Animations
@mixin animation($animation) {
  -webkit-animation: $animation;
  -o-animation: $animation;
  animation: $animation;
}
@mixin animation-name($name) {
  -webkit-animation-name: $name;
  animation-name: $name;
}
@mixin animation-duration($duration) {
  -webkit-animation-duration: $duration;
  animation-duration: $duration;
}
@mixin animation-timing-function($timing-function) {
  -webkit-animation-timing-function: $timing-function;
  animation-timing-function: $timing-function;
}
@mixin animation-delay($delay) {
  -webkit-animation-delay: $delay;
  animation-delay: $delay;
}
@mixin animation-iteration-count($iteration-count) {
  -webkit-animation-iteration-count: $iteration-count;
  animation-iteration-count: $iteration-count;
}
@mixin animation-direction($direction) {
  -webkit-animation-direction: $direction;
  animation-direction: $direction;
}
@mixin animation-fill-mode($fill-mode) {
  -webkit-animation-fill-mode: $fill-mode;
  animation-fill-mode: $fill-mode;
}

// Backface visibility
// Prevent browsers from flickering when using CSS 3D transforms.
// Default value is `visible`, but can be changed to `hidden`

@mixin backface-visibility($visibility) {
  -webkit-backface-visibility: $visibility;
  -moz-backface-visibility: $visibility;
  backface-visibility: $visibility;
}

// Drop shadows
//
// Note: Deprecated `.box-shadow()` as of v3.1.0 since all of Bootstrap's
// supported browsers that have box shadow capabilities now support it.

@mixin box-shadow($shadow...) {
  -webkit-box-shadow: $shadow; // iOS <4.3 & Android <4.1
  box-shadow: $shadow;
}

// Box sizing
@mixin box-sizing($boxmodel) {
  -webkit-box-sizing: $boxmodel;
  -moz-box-sizing: $boxmodel;
  box-sizing: $boxmodel;
}

// CSS3 Content Columns
@mixin content-columns($column-count, $column-gap: $grid-gutter-width) {
  -webkit-column-count: $column-count;
  -moz-column-count: $column-count;
  column-count: $column-count;
  -webkit-column-gap: $column-gap;
  -moz-column-gap: $column-gap;
  column-gap: $column-gap;
}

// Optional hyphenation
@mixin hyphens($mode: auto) {
  word-wrap: break-word;
  -webkit-hyphens: $mode;
  -moz-hyphens: $mode;
  -ms-hyphens: $mode; // IE10+
  -o-hyphens: $mode;
  hyphens: $mode;
}

// Placeholder text
@mixin webPlaceholder($color: $input-color-placeholder) {
  // Firefox
  &::-moz-placeholder {
    color: $color;
    opacity: 1; // Override Firefox's unusual default opacity; see https://github.com/twbs/bootstrap/pull/11526
  }
  &:-ms-input-placeholder { color: $color; } // Internet Explorer 10+
  &::-webkit-input-placeholder  { color: $color; } // Safari and Chrome
}

// Transformations
@mixin scale($ratio...) {
  -webkit-transform: scale($ratio);
  -ms-transform: scale($ratio); // IE9 only
  -o-transform: scale($ratio);
  transform: scale($ratio);
}

@mixin scaleX($ratio) {
  -webkit-transform: scaleX($ratio);
  -ms-transform: scaleX($ratio); // IE9 only
  -o-transform: scaleX($ratio);
  transform: scaleX($ratio);
}
@mixin scaleY($ratio) {
  -webkit-transform: scaleY($ratio);
  -ms-transform: scaleY($ratio); // IE9 only
  -o-transform: scaleY($ratio);
  transform: scaleY($ratio);
}
@mixin skew($x, $y) {
  -webkit-transform: skewX($x) skewY($y);
  -ms-transform: skewX($x) skewY($y); // See https://github.com/twbs/bootstrap/issues/4885; IE9+
  -o-transform: skewX($x) skewY($y);
  transform: skewX($x) skewY($y);
}
@mixin translate($x, $y) {
  -webkit-transform: translate($x, $y);
  -ms-transform: translate($x, $y); // IE9 only
  -o-transform: translate($x, $y);
  transform: translate($x, $y);
}
@mixin translate3d($x, $y, $z) {
  -webkit-transform: translate3d($x, $y, $z);
  transform: translate3d($x, $y, $z);
}
@mixin rotate($degrees) {
  -webkit-transform: rotate($degrees);
  -ms-transform: rotate($degrees); // IE9 only
  -o-transform: rotate($degrees);
  transform: rotate($degrees);
}
@mixin rotateX($degrees) {
  -webkit-transform: rotateX($degrees);
  -ms-transform: rotateX($degrees); // IE9 only
  -o-transform: rotateX($degrees);
  transform: rotateX($degrees);
}
@mixin rotateY($degrees) {
  -webkit-transform: rotateY($degrees);
  -ms-transform: rotateY($degrees); // IE9 only
  -o-transform: rotateY($degrees);
  transform: rotateY($degrees);
}
@mixin perspective($perspective) {
  -webkit-perspective: $perspective;
  -moz-perspective: $perspective;
  perspective: $perspective;
}
@mixin perspective-origin($perspective) {
  -webkit-perspective-origin: $perspective;
  -moz-perspective-origin: $perspective;
  perspective-origin: $perspective;
}
@mixin transform-origin($origin) {
  -webkit-transform-origin: $origin;
  -moz-transform-origin: $origin;
  -ms-transform-origin: $origin; // IE9 only
  transform-origin: $origin;
}


// Transitions

@mixin transition($transition...) {
  -webkit-transition: $transition;
  -o-transition: $transition;
  transition: $transition;
}
@mixin transition-property($transition-property...) {
  -webkit-transition-property: $transition-property;
  transition-property: $transition-property;
}
@mixin transition-delay($transition-delay) {
  -webkit-transition-delay: $transition-delay;
  transition-delay: $transition-delay;
}
@mixin transition-duration($transition-duration...) {
  -webkit-transition-duration: $transition-duration;
  transition-duration: $transition-duration;
}
@mixin transition-timing-function($timing-function) {
  -webkit-transition-timing-function: $timing-function;
  transition-timing-function: $timing-function;
}
@mixin transition-transform($transition...) {
  -webkit-transition: -webkit-transform $transition;
  -moz-transition: -moz-transform $transition;
  -o-transition: -o-transform $transition;
  transition: transform $transition;
}


// User select
// For selecting text on the page

@mixin user-select($select) {
  -webkit-user-select: $select;
  -moz-user-select: $select;
  -ms-user-select: $select; // IE10+
  user-select: $select;
}

// Gradients



// Horizontal gradient, from left to right
//
// Creates two color stops, start and end, by specifying a color and position for each color stop.
// Color stops are not available in IE9 and below.
@mixin gradient-horizontal($start-color: #555, $end-color: #333, $start-percent: 0%, $end-percent: 100%) {
  background-image: -webkit-linear-gradient(left, $start-color $start-percent, $end-color $end-percent); // Safari 5.1-6, Chrome 10+
  background-image: -o-linear-gradient(left, $start-color $start-percent, $end-color $end-percent); // Opera 12
  background-image: linear-gradient(to right, $start-color $start-percent, $end-color $end-percent); // Standard, IE10, Firefox 16+, Opera 12.10+, Safari 7+, Chrome 26+
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#{ie-hex-str($start-color)}', endColorstr='#{ie-hex-str($end-color)}', GradientType=1); // IE9 and down
}

// Vertical gradient, from top to bottom
//
// Creates two color stops, start and end, by specifying a color and position for each color stop.
// Color stops are not available in IE9 and below.
@mixin gradient-vertical($start-color: #555, $end-color: #333, $start-percent: 0%, $end-percent: 100%) {
  background-image: -webkit-linear-gradient(top, $start-color $start-percent, $end-color $end-percent);  // Safari 5.1-6, Chrome 10+
  background-image: -o-linear-gradient(top, $start-color $start-percent, $end-color $end-percent);  // Opera 12
  background-image: linear-gradient(to bottom, $start-color $start-percent, $end-color $end-percent); // Standard, IE10, Firefox 16+, Opera 12.10+, Safari 7+, Chrome 26+
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#{ie-hex-str($start-color)}', endColorstr='#{ie-hex-str($end-color)}', GradientType=0); // IE9 and down
}

@mixin gradient-directional($start-color: #555, $end-color: #333, $deg: 45deg) {
  background-repeat: repeat-x;
  background-image: -webkit-linear-gradient($deg, $start-color, $end-color); // Safari 5.1-6, Chrome 10+
  background-image: -o-linear-gradient($deg, $start-color, $end-color); // Opera 12
  background-image: linear-gradient($deg, $start-color, $end-color); // Standard, IE10, Firefox 16+, Opera 12.10+, Safari 7+, Chrome 26+
}
@mixin gradient-horizontal-three-colors($start-color: #00b3ee, $mid-color: #7a43b6, $color-stop: 50%, $end-color: #c3325f) {
  background-image: -webkit-linear-gradient(left, $start-color, $mid-color $color-stop, $end-color);
  background-image: -o-linear-gradient(left, $start-color, $mid-color $color-stop, $end-color);
  background-image: linear-gradient(to right, $start-color, $mid-color $color-stop, $end-color);
  background-repeat: no-repeat;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#{ie-hex-str($start-color)}', endColorstr='#{ie-hex-str($end-color)}', GradientType=1); // IE9 and down, gets no color-stop at all for proper fallback
}
@mixin gradient-vertical-three-colors($start-color: #00b3ee, $mid-color: #7a43b6, $color-stop: 50%, $end-color: #c3325f) {
  background-image: -webkit-linear-gradient($start-color, $mid-color $color-stop, $end-color);
  background-image: -o-linear-gradient($start-color, $mid-color $color-stop, $end-color);
  background-image: linear-gradient($start-color, $mid-color $color-stop, $end-color);
  background-repeat: no-repeat;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#{ie-hex-str($start-color)}', endColorstr='#{ie-hex-str($end-color)}', GradientType=0); // IE9 and down, gets no color-stop at all for proper fallback
}
@mixin gradient-radial($inner-color: #555, $outer-color: #333) {
  background-image: -webkit-radial-gradient(circle, $inner-color, $outer-color);
  background-image: radial-gradient(circle, $inner-color, $outer-color);
  background-repeat: no-repeat;
}
@mixin gradient-striped($color: rgba(255,255,255,.15), $angle: 45deg) {
  background-image: -webkit-linear-gradient($angle, $color 25%, transparent 25%, transparent 50%, $color 50%, $color 75%, transparent 75%, transparent);
  background-image: -o-linear-gradient($angle, $color 25%, transparent 25%, transparent 50%, $color 50%, $color 75%, transparent 75%, transparent);
  background-image: linear-gradient($angle, $color 25%, transparent 25%, transparent 50%, $color 50%, $color 75%, transparent 75%, transparent);
}